import {get} from 'axios'
import {debounce} from 'throttle-debounce'
import UniqBy from 'lodash.uniqby'
import Moment from 'moment'

export default debounce(1000, false, (mode = 'get', $state) ->
  @loading = true
  {
    dates
    keywords

  } = @search_filters
  q = {
    title_cont_any: keywords.split(' ') if keywords
    auctions_with_date: dates if dates

  }
  request = get('/subastas/search.json', {params: {id: @id, q, @page, sort: [@sort]}})
  if mode == 'infinite'
    request.then ({data}) =>
      @loading = false
      quantity = data.auctions.length
      new_auctions = @auctions
      new_auctions.push(data.auctions...)
      @auctions = UniqBy(new_auctions, 'id')
      if (quantity < 15)
        $state.complete()
        @page = 0
      else
        @page++
        $state.loaded()
  if mode == 'get'
    auctions = []
    request.then ({data}) =>
      @page++
      @auctions = data.auctions
      @loading = false
)
