#External Libraries
import InfiniteLoading from 'vue-infinite-loading'
import Vue from 'vue/dist/vue.esm'
import TurbolinksAdapter from 'vue-turbolinks'
import VueAutonumeric from 'vue-autonumeric'

import UniqBy from 'lodash.uniqby'

# Local imports
import fetchAuctions from '../methods/auctions/fetch-auctions'
import AddAuction from '../methods/auctions/add_auction'

Vue.use(TurbolinksAdapter)
document.addEventListener 'turbolinks:load', () ->
  el = document.getElementById 'index-auctions'
  return false unless el?

  data = ->
    {searchParams} = new URL(window.location.href)
    # Default values
    page = 1
    sort =
      created_at: 'desc'
      title: false

    # Negotiation
    page |= parseInt searchParams.get('page')
    keywords = searchParams.get('keyword')
    dates = searchParams.get('date')
    {
      auctions: []
      filter_interactions: 1
      quantity: 1
      page
      loading: true
      search_filters: {
        keywords
        dates
      }
      sort
    }
  new Vue {
    el
    data

    methods: {
      fetchAuctions
      AddAuction
      filtersChanged: ->
        @filter_interactions++
        @page = 1
        @fetchAuctions()
      toggleProperty: (property) ->
        old_value = @search_filters[property]
        @search_filters[property] = !old_value
      assignProperty: (property, value) ->
        @search_filters[property] = value
      clearProperty: (property, reload = true) ->
        @search_filters[property] = '';
        @fetchAuctions() if reload
      infiniteHandler: ($state) ->
        @fetchAuctions('infinite', $state)
      resetState: ->
        history.pushState({}, 'Ver Todas', document.location.origin + document.location.pathname)
        Object.assign @$data, data()
      sortControl: (order) ->
        @sort = order
        @filtersChanged()
    }
    watch: {
      'search_filters.keywords': -> @filtersChanged()
      'search_filters.dates': -> @filtersChanged()
    }
    components: {
      InfiniteLoading
    }
    computed: {
      active_filters: ->
        f = @search_filters
        f.keywords || f.dates
    }
  }
